// Here you can add other styles
.DateInput {
    width: 144px !important;
}

.select-file-btn {
    border-radius: 4px;
    background: #00adff;
    padding: 0.375rem 0.75rem;
    color: white;
    cursor: pointer;
    &.disabled {
        background: #82d4fb;
        cursor: not-allowed;
    }
}
